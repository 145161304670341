html, body {
  height: auto!important;
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: #FFBF5F;
}

:root {
  --animate-duration: 400ms!important;
  --animate-delay: 0.9s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.node__root {
  fill: #26acff!important;
  stroke-width: 0!important;
}

.node__branch {
  fill: #ff9a26 !important;
  stroke-width: 0!important;
}

.node__leaf {
  fill: #ff9a26 !important;
  stroke-width: 0!important;
}
.tree-root .rd3t-label__title {
  stroke-width: 0.05px;

}

.node__link {
  stroke: #58b8ff!important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #FFF4EA;
  color: #8C635B;
}

.ant-modal-content {
  border-radius: 15px;
  overflow: hidden;
}

.delay-300ms {
  animation-delay: 300ms;
}
.delay-600ms {
  animation-delay: 600ms;
}
.delay-1s {
  animation-delay: 1s;
}

input[type="checkbox"]:checked { /* change "blue" browser chrome to yellow */
  filter: invert(100%) hue-rotate(18deg) brightness(1.2);
}
